<div class="task-list-header">
    <div class="toggle-task-list">
        <mat-button-toggle-group 
        name="toggleTaskList" 
        value="todo" 
        aria-label="toggle between to do and completed tasks" 
        (change)="toggleTaskList()">
            <mat-button-toggle value="todo" selected>To do</mat-button-toggle>
            <mat-button-toggle value="completed">Completed</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="tasks-info">
        <ng-container *ngIf="tasksCount > 0">{{ tasksCount }} tasks {{(completed$ | async) ? 'completed' : 'to be done' }}</ng-container>
    </div>
</div>


<div *ngFor="let task of (taskList$ | async)">
    <div class="task" *ngIf="task.completed === (completed$ | async)">
        <app-task-item [task]="task"></app-task-item>
    </div>
</div>