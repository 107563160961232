import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.scss']
})
export class TaskItemComponent implements OnInit {

  constructor() { }

  @Input() task: any;
  completed!: boolean;

  ngOnInit(): void {
    this.completed = this.task.status === 'completed';
  }



}
