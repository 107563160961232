import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { StoreService } from '../services/store-service/store.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  storeNumber: string = '';
  isContentReady: boolean = false;
  
  constructor(
    private router: Router,
    private storeService: StoreService,
    private authService: AuthService,
  ) { 
    this.startStore();
  }
  
  ngOnInit(): void {
  }

  startStore() {
    this.authService.getUserStore(
      (err, storeNumber) => {
        if(err) {
          this.onLogout(null);
          alert(err);
          return;
        }
        console.log({ storeNumber });
        this.storeNumber = storeNumber;
        this.storeService.start(this.storeNumber,
          (e: any) => {
            if (e.err) {
              alert('Could not connect to server.');
              this.onLogout(null);
            }
            this.isContentReady = true;
            return;
          }
        );
      }
    );
  }

  onLogout($event: any): void {
    $event && $event.preventDefault();
    this.authService.logOut();
    this.router.navigate([''])
  }

  ngOnDestroy(): void {
    this.storeService.stop();
  }
}
