import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRestroom } from 'src/app/models/restroom';
import { StoreService } from 'src/app/services/store-service/store.service';

@Component({
  selector: 'app-restroom-card',
  templateUrl: './restroom-card.component.html',
  styleUrls: ['./restroom-card.component.scss']
})
export class RestroomCardComponent implements OnInit {

  constructor(
    private storeService: StoreService,
  ) { }

  @Input() restroom!: IRestroom;
  feedbackIcon$!: Observable<string>;

  ngOnInit(): void {
    this.feedbackIcon$ = this.storeService.getFeedback(this.restroom.restroomId)
    .pipe(
      map(data => this.rateIcon(data.feedback || '0')),
    );
  }

  rateIcon(feedback: string): string {
    const rate = !Number.isInteger(parseInt(feedback)) || !feedback ? 0 : parseInt(feedback);

    if (rate === 0) {
      return 'pending';
    }
    if (rate <= 2) {
      return 'sentiment_very_dissatisfied';
    }

    if (rate <= 3) {
      return 'sentiment_neutral';
    }
    return 'sentiment_very_satisfied';
  }

}
