import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-percentage-bar-chart',
  templateUrl: './percentage-bar-chart.component.html',
  styleUrls: ['./percentage-bar-chart.component.scss']
})
export class PercentageBarChartComponent implements OnInit {

  constructor() { }

  @Input() percent!: number;

  percentString!: string;
  status!: 'allgood' | 'attention' | 'critical';

  ngOnInit(): void {
    this.percentString = this.percent + '%';

    this.status = this.percent < 30 ? 'critical' : (
      this.percent < 50 ? 'attention' : 'allgood');

    console.log('status', this.status)
  }

}
