import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-medal',
  templateUrl: './medal.component.html',
  styleUrls: ['./medal.component.scss']
})
export class MedalComponent implements OnInit {

  constructor() { }

  @Input() type: 'gold' | 'silver' | 'bronze' = 'gold';
  number: string = '';


  ngOnInit(): void {
    this.number = Medal[this.type];
  }

}

export enum Medal {
  gold = '1',
  silver = '2',
  bronze = '3'
}
