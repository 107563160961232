import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-indicator-item',
  templateUrl: './indicator-item.component.html',
  styleUrls: ['./indicator-item.component.scss']
})
export class IndicatorItemComponent implements OnInit {

  constructor() { }

  @Input() iconName!: string;
  @Input() description!: string;
  @Input() value!: string;

  ngOnInit(): void {
  }

}
