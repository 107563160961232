import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IScoreboard } from '../models/scoreboard';
import { StoreService } from '../services/store-service/store.service';

@Component({
  selector: 'app-scoreboard',
  templateUrl: './scoreboard.component.html',
  styleUrls: ['./scoreboard.component.scss']
})
export class ScoreboardComponent implements OnInit {

  constructor(
    private storeService: StoreService,
  ) { }

  scoreboard$!: Observable<IScoreboard>;

  ngOnInit(): void {
    this.scoreboard$ = this.storeService.getScoreboard();
  }
}
