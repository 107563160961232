import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  isLoading: boolean = false;
  username: string = ''
  password: string = ''

  new_password: string = '';
  confirm_password: string = '';

  error: string = '';
  hasToCompletePassword: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit(): void { 
    let isAuth = this.authService.isLoggedIn();
    if(isAuth) {
      this.router.navigate(['home']);
    }
  }

  onSignIn(form: NgForm){
    this.isLoading = true;
    if (!form.valid) {
      this.isLoading = false;
      this.error = 'error'
      alert('error');
      return;
    }

    this.authService.logIn(this.username, this.password, {
      success: () => {
        this.router.navigate(['home']);
      },
      error: (err) => {
        alert(err.message || JSON.stringify(err));
        this.isLoading = false;
      },
      changePassword: () => {
        this.hasToCompletePassword = true;
      }
    });
  }

  completePassword(form: NgForm) {
    if (!form.valid) {
      alert('error');
      return;
    }

    if (this.new_password !== this.confirm_password) {
      alert('the passwords do not match');
      return;
    }

    this.authService.completeNewPassword(this.new_password, {
      success: () => this.router.navigate(['home']),
      error: (err) => alert(err),
    });


  }
}
