import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { ITask, taskStatus } from '../../models/task';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { IRestroom } from '../../models/restroom';
import { WebsocketService } from '../websocket-service/websocket.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private restroomList: IRestroom[] = [];
  
  constructor(
    private websocket: WebsocketService,
  ) { }

  start(storeId: string, cbk: Function = () => ({})) {
    this.websocket.connect((e: any) => {
      this.registryStore(storeId);
      cbk(e);
      return;
    }
    );
  }

  stop() {
    this.websocket.disconnect();
  }

  registryStore(storeId: string) {
    this.websocket.emitData('registry_store', storeId);
  }

  getTaskList() {
    return  this.websocket.websocketMessage$
      .pipe(
        filter(data => data && data.hasOwnProperty('TaskList')),
        mergeMap(data => timer(100, 60000).pipe(map(() => data))), // this is to keep updating the remaining time
        map((resp: any) => resp.TaskList),
        map(data => data.map((task: ITask) => ({ 
          ...task,
          taskStart: this.formatDate(task.taskStart, task.timeZoneFactor),
          taskEnd: this.formatDate(task.taskEnd, task.timeZoneFactor),
          completed: !!task.taskEnd,
          status: this.taskStatus(task),
          timeRemaining: this.taskTimeRemaining(task),
        }))),
        // tap(data => console.log('getTaskList', data))
      );
  }

  private formatDate(date: string, timeZoneFactor: any) {
    const timeZone = this.formatTimeZone(timeZoneFactor);
    return date + ' ' + timeZone;
  }

  private taskTimeRemaining(task: ITask) {
    const sla = ((isNaN(task.sla as any) ||  !task.sla) ? 60 : task.sla) as number;
    const minutesSince = this.minutesSince(this.formatDate(task.taskStart, task.timeZoneFactor));
    return sla - minutesSince;
  }

  private taskStatus(task: ITask): taskStatus {
    if (!!task.taskEnd) {
      return 'completed';
    }

    const sla = ((isNaN(task.sla as any) ||  !task.sla) ? 60 : task.sla) as number;
    const minutesSince = this.minutesSince(task.taskStart);
    const slaOnTime = Math.floor(sla*0.33);
    const slaAttention = Math.floor(sla*0.66);
    
    if (minutesSince <= slaOnTime) {
      return 'ontime';
    }

    if (minutesSince <= slaAttention) {
      return 'attention';
    }

    return 'critical';
    
  }

  getRestroomList() {   
    return this.getStatus().pipe(
      tap((data: any) => {
        const restroomId = data.restroomId;
        let restroom = this.restroomList?.find(r => r.restroomId === restroomId) as any;
        if (!restroom) {
          restroom = { restroomId };
          this.restroomList.push(restroom)
        }
        restroom[data.statusTypeId] = data.statusTypeId === 'temperature' ?
          parseInt(this.celsiusToFahrenheit(data.statusValue) + '', 10) : data.statusValue;
      }),
      map(() => this.restroomList),
    );
  }

  celsiusToFahrenheit(celsius: number) {
    return (celsius * 9.00/5.00) + 32.00
  }

  getStatus() {   
    return  this.websocket.websocketMessage$
    .pipe(
      filter( data => data && data.hasOwnProperty('status')),
      map( data => data.status),
    );
  }

  getFeedback(restroomId: string) {
    return this.websocket.websocketMessage$
    // this.websocket.listenToTopic('feedback')
    .pipe(
      filter( data => data && data.hasOwnProperty('feedback')),
      map((resp: any) => resp.feedback),
      filter(data => data.restroomId === restroomId),
    );
  }


  getScoreboard() {
    return  this.websocket.websocketMessage$
    .pipe(
      filter( data => data && data.hasOwnProperty('scoreboard')),
      map((resp: any) => resp.scoreboard),
      map((resp: any) => resp.data),
    );
  }

  formatTimeZone(timezone: any) {
    const signal = timezone < 0 ? '-' : ''; 
    const absNumber = Math.abs(timezone);
    return 'GMT' + signal + (absNumber < 10 ? '0' : '') + absNumber + '00';
  }

  minutesSince(d1: string, d2: string | null = null) {
    const date1: any = new Date(d1);
    const date2: any = d2 ? new Date(d2) : new Date();
    const diffTime = Math.abs(date2 - date1);
    let diffMinutes = Math.floor(diffTime / (1000 * 60)); 
    
    return diffMinutes
  }

  timeSinceString(d1: string , d2: string | null = null) {
    if (!d1) {
      return '';
    }
    let diffMinutes = this.minutesSince(d1, d2);
    if (diffMinutes < 60) {
      return diffMinutes + 'm';
    }

    let diffHours =  Math.floor(diffMinutes / 60);

    if (diffHours < 24) {
      diffMinutes =  diffMinutes - (diffHours * 60);
      return diffHours + 'h' +
        (diffMinutes > 0 ? + ' ' + diffMinutes + 'm' : '');
    }

    const diffDays = Math.floor(diffMinutes / (60 * 24));
    return diffDays + 'd';
  }
}

