import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskItemComponent } from './task-list/task-item/task-item.component';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { RestroomCardComponent } from './restroom-list/restroom-card/restroom-card.component';
import { PercentageBarChartComponent } from './restroom-list/restroom-card/percentage-bar-chart/percentage-bar-chart.component';
import { RestroomListComponent } from './restroom-list/restroom-list.component';
import { IndicatorItemComponent } from './restroom-list/restroom-card/indicator-item/indicator-item.component';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { HttpClientModule } from '@angular/common/http';
import { ScoreboardComponent } from './scoreboard/scoreboard.component';
import { MedalComponent } from './scoreboard/medal/medal.component';
import { HomeComponent } from './home/home.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { FormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    AppComponent,
    TaskListComponent,
    TaskItemComponent,
    RestroomCardComponent,
    PercentageBarChartComponent,
    RestroomListComponent,
    IndicatorItemComponent,
    ScoreboardComponent,
    MedalComponent,
    HomeComponent,
    SignInComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatCardModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
