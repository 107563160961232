<div class="scoreboard">
    <div class="scoreboard-header">
        <span class="header-title">Scoreboard</span>
        <span class="material-icons header-icon">emoji_events</span>
    </div>

    <div class="scoreboard-content" *ngIf="(scoreboard$ | async) as sb">
        <div class="your-score">
            <div class="your-score__title">Your Score</div>
            <div class="your-score__value">{{ sb.score }}</div>
            <div class="your-score__position">{{ sb.position }}° position</div>
        </div>
        <div class="score-rank">
            <div class="first-place score-rank__item">
                <div class="score-rank__medal"><app-medal type="gold"></app-medal></div>
                <div class="score-rank__name">{{ sb.firstPlace.storeName }}</div>
                <div class="score-rank__value">{{ sb.firstPlace.score }}</div>
            </div>
            <div class="second-place score-rank__item">
                <div class="score-rank__medal"><app-medal type="silver"></app-medal></div>
                <div class="score-rank__name">{{ sb.secondPlace.storeName }}</div>
                <div class="score-rank__value">{{ sb.secondPlace.score }}</div>
            </div>
            <div class="third-place score-rank__item">
                <div class="score-rank__medal"><app-medal type="bronze"></app-medal></div>
                <div class="score-rank__name">{{ sb.thirdPlace.storeName }}</div>
                <div class="score-rank__value">{{ sb.thirdPlace.score }}</div>
            </div>
        </div>
    </div>
</div>