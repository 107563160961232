<div class="page-wrapper">
    <div class="logo">
        <img src="./assets/logos/logo_cares-horizontal.png" class="mapco-logo">
    </div>

    <form *ngIf="!hasToCompletePassword" class="form" (submit)="onSignIn(signInForm)" #signInForm="ngForm">
        <div class="form-field">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Username</mat-label>
                <input matInput name="username" ngModel type="text" #usernameInput="ngModel" [(ngModel)]="username" placeholder="Enter Username" required>
            </mat-form-field>
        </div>
        
        <div class="form-field">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Password</mat-label>
                <input matInput name="password" ngModel type="password" #passwordInput="ngModel" [(ngModel)]="password" minlength="8" placeholder="Enter Password" required>
            </mat-form-field>
        </div>
        <div class="button-wrapper">
            <button class="login-button" mat-stroked-button color="primary" type="submit">Login</button>
        </div>
    </form>

    <form *ngIf="hasToCompletePassword" class="form" (submit)="completePassword(ChangePwdForm)" #ChangePwdForm="ngForm">
        <p class="new-pwd--title">Please, set a new password</p>
        <p class="new-pwd--description">You need to update your password because this is the first time you are signing in.</p>
        <div class="form-field">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>New password</mat-label>
                <input matInput
                    [(ngModel)]="new_password" ngModel 
                    type="password" minlength="8" 
                    #newPasswordInput="ngModel" required 
                    name="new_password" placeholder="New Password" 
                >
            </mat-form-field>
        </div>
      
        <div class="form-field">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Confirm Password</mat-label>
                <input matInput
                    [(ngModel)]="confirm_password" ngModel 
                    type="password" minlength="8" 
                    #confirmPasswordInput="ngModel" required 
                    name="confirm_password" placeholder="Confirm Password" 
                >
            </mat-form-field>
        </div>
        <div class="button-wrapper">
            <button class="login-button" mat-stroked-button color="primary" type="submit">Change Password</button>
        </div>
    </form>  
</div>