<mat-card>
    <div *ngIf="!completed" class="chip-alert {{ task.status }}">
        {{ task.timeRemaining }} min remaining
    </div>
    <span *ngIf="completed" class="chip-alert {{ task.status }} material-icons">done</span>
    <mat-card-subtitle>{{ task.restroomId }}</mat-card-subtitle>
    <mat-card-title>{{ task.taskId }} - {{ task.taskName }}</mat-card-title>
    <mat-card-content>
        <p *ngIf="!completed">complete it in {{ task.sla }} minutes</p>
        <p *ngIf="completed">completed on {{ task.taskEnd | date: 'medium' }}</p>
    </mat-card-content>
</mat-card>
