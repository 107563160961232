import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StoreService } from '../services/store-service/store.service';

@Component({
  selector: 'app-restroom-list',
  templateUrl: './restroom-list.component.html',
  styleUrls: ['./restroom-list.component.scss']
})
export class RestroomListComponent implements OnInit {

  constructor(
    private storeService: StoreService,
  ) { }

  restrooms$!: Observable<any[]>;

  ngOnInit(): void {
    this.restrooms$ = this.storeService.getRestroomList();
  }

}
