import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITask } from '../models/task';
import { StoreService } from '../services/store-service/store.service';
import { map, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {

  constructor(
    private taskService: StoreService,
  ) { }

  taskList$!: Observable<ITask[]>;
  tasksCount: number = 0;
  completed$ = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {
    this.taskList$ = this.getTaskList();
  }

  toggleTaskList() {
    this.tasksCount = 0;
    this.completed$.next(!this.completed$.value);
  }

  getTaskList() {
    return this.taskService.getTaskList()
    .pipe(
      mergeMap((tasks: ITask[]) => this.completed$
        .pipe(
          map((c: boolean) => tasks.filter((task: ITask) => task.completed === c)),
          tap(tasks => this.tasksCount = tasks.length),
        )
      )
    );
  }

}
