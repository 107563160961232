import { Injectable } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  
  username: string = '';

  cognitoUser: any;
  sessionUserAttributes: any;

  getCognitoUserPool() {
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
      ClientId: environment.cognitoAppClientId // Your client id here
    };

    return new CognitoUserPool(poolData);
  }

  isLoggedIn(): boolean {
    let isAuth = false;

    const userPool = this.getCognitoUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          alert(err.message || JSON.stringify(err));
        }
        isAuth = session.isValid();
      });
    }
    return isAuth;
  }

  logIn(user: string, password: string, callbacks: ILogInCallbacks) {
    let authenticationDetails = new AuthenticationDetails({
        Username: user,
        Password: password,
    });
   
    let userPool = this.getCognitoUserPool();
    let userData = { Username: user, Pool: userPool };
    this.cognitoUser = new CognitoUser(userData);
    this.cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result: any) => {
        callbacks.success(result);
      },
      onFailure: (err: any) => {
        callbacks.error(err);
      },
      newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
        delete userAttributes.email_verified; // the api doesn't accept this field back
        delete userAttributes.phone_number_verified;
        delete userAttributes.email;
        this.sessionUserAttributes = userAttributes;
        
        if (callbacks.changePassword) {
          callbacks.changePassword({ userAttributes, requiredAttributes });
        }
      }
    });
  }

  completeNewPassword(newPassword: string, callbacks: ILogInCallbacks) {
    this.cognitoUser.completeNewPasswordChallenge(newPassword, this.sessionUserAttributes, {
      onSuccess: (data: any) => {
        callbacks.success(data);
      },
      onFailure: (err: any) => {
        callbacks.error(err);
      },
    });
  }

  getUserStore(callback: (err:any, storeNumber: any)=> void) {
    let storeNumber: string | undefined = '';

    const userPool = this.getCognitoUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser === null) {
      return
    }
    cognitoUser.getSession((err: any, session: any) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      
      cognitoUser.getUserAttributes(async (err, result) => {
        if (err) {
          callback(err, null);
          return;
        }
        
        const storeAttr = result?.find(a => a.Name === 'custom:storeNumber');
        storeNumber = storeAttr?.Value;

        callback(null, storeNumber);
      });
    });
  }

  logOut() {
    let userPool = this.getCognitoUserPool();
    let cognitoUser = userPool.getCurrentUser();
    cognitoUser?.signOut();
  }
}

export interface ILogInCallbacks {
  success: (
    session: CognitoUserSession,
    userConfirmationNecessary?: boolean
  ) => void;
  error: (err: any) => void;
  changePassword?: (
    data: any
  ) => void;
}