import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  constructor() { }

  socket: any;
  websocketMessage$ = new BehaviorSubject<any>(null);

  get isConnected() {
    return this.socket && this.socket.readyState === WebSocket.OPEN
  }

  connect(cbk: Function) { 
    if (this.isConnected) {
      this.disconnect();
    }

    this.socket = new WebSocket(environment.API_URL);

    this.socket.addEventListener('open', (event: any) => {
      console.log('[websocket]: connected', { event });
      cbk(event);
      setInterval(() => this.emitData('ping', 'ping'), 60000); //keep connection alive
      return;
    });

    this.socket.onmessage = (event: any) => this.onMessage(event);

    addEventListener('error', err => {
      cbk({ err });
     });

     addEventListener('close', event => { 
      console.log('[websocket]: connection closed', { event });
     });
   
  }

  onMessage($event: any) {
    
    if (!$event.data) {
      return;
    }
    const jsonData = JSON.parse($event.data)
    console.log('[websocket]: onmessage', jsonData);
    this.websocketMessage$.next(jsonData);
  }

  emitData(topic: string, data: any) {
    console.log('[websocket]: emitting data', { topic, data });
    const payload = {
      action: topic,
      data
    }
    this.socket.send(JSON.stringify(payload));
  }

  // listenToTopic(topic: string) {
  //   console.log('[websocket]: listening to ' + topic);
  //   let observable = new Observable(observer => {
  //     this.socket.addEventListener(topic, (event: any) => {
  //       console.log('[websocket]: message from server', event.data);
  //       observer.next(event.data);
  //     });
  //   });
  //   return observable;
  // }

  disconnect() {
    this.socket.close();
  }

}
