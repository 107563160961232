<div class="card">
    <div class="card--header">
        {{ restroom.restroomId }}
        <span class="material-icons card-header--icon {{ (feedbackIcon$ | async) || 'pending' }}">{{ (feedbackIcon$ | async) || 'pending' }}</span>
    </div>
    <div class="card--content">
        <app-indicator-item iconName="air" value="{{restroom.airQuality}}" description="air quality"></app-indicator-item>
        <app-indicator-item iconName="water_drop" value="{{restroom.humidity}}%" description="humidity"></app-indicator-item>
        <app-indicator-item iconName="thermostat" value="{{restroom.temperature}}°F" description="temperature"></app-indicator-item>
    </div>

    <!-- <div class="card--content">
        <app-indicator-item iconName="schedule" value="{{restroom.timeSinceLastChecked}}" description="last checked"></app-indicator-item>
        <app-indicator-item iconName="people" value="{{restroom.visitorsCount}}" description="visits since"></app-indicator-item>
        <app-indicator-item iconName="pending_actions" value="{{restroom.actionsNeeded}}" description="actions needed"></app-indicator-item>
    </div> -->
</div>