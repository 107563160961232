<div class="header">
  <div class="mapco-cares">
    <img src="./assets/logos/logo_cares-horizontal.png" class="mapco-logo">
    <!--<div class="cares">C.A.R.E.S.</div>-->
  </div>
  <span *ngIf="!!storeNumber" class="store-number">Store #{{ storeNumber }}</span>
  <a *ngIf="!!storeNumber" class="logout" (click)="onLogout($event)">
    <span class="material-icons">logout</span>
    Logout
  </a>
</div>
  
  <div class="page-wrapper" *ngIf="isContentReady">
    <div class="tasks panel">
      <app-task-list></app-task-list>
      <app-scoreboard></app-scoreboard>
    </div>
    <div class="info panel">
      
      <app-restroom-list></app-restroom-list>
      
    </div>
  </div>
